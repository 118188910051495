const options = {
  threshold:1
}

doc_list = Array.from(document.getElementsByClassName("row-card-12"))
doc_list.forEach( function (element) {
  function kantan(entries) {
    if (entries[0].intersectionRatio !== 0) {
      element.classList.add("alpha");
    }
}
  let observer = new IntersectionObserver(kantan, options);
  observer.observe(element);
})

// プログレスバーの表示時びよーんとする --START--

let progress_1= document.getElementById("progress-1");
function progress_extended_1(entries) {
  if (entries[0].intersectionRatio !== 0) {
    progress_1.classList.add("progress-css-alpha-1");
  }
}
let observer_progress_1 = new IntersectionObserver(progress_extended_1, options);
observer_progress_1.observe(progress_1);

let progress_2= document.getElementById("progress-2");
function progress_extended_2(entries) {
  if (entries[0].intersectionRatio !== 0) {
    progress_2.classList.add("progress-css-alpha-2");
  }
}
let observer_progress_2 = new IntersectionObserver(progress_extended_2, options);
observer_progress_2.observe(progress_2);

let progress_3= document.getElementById("progress-3");
function progress_extended_3(entries) {
  if (entries[0].intersectionRatio !== 0) {
    progress_3.classList.add("progress-css-alpha-3");
  }
}
let observer_progress_3 = new IntersectionObserver(progress_extended_3, options);
observer_progress_3.observe(progress_3);

let progress_4= document.getElementById("progress-4");
function progress_extended_4(entries) {
  if (entries[0].intersectionRatio !== 0) {
    progress_4.classList.add("progress-css-alpha-4");
  }
}
let observer_progress_4 = new IntersectionObserver(progress_extended_4, options);
observer_progress_4.observe(progress_4);

// プログレスバーの表示時びよーんとする --END--
